<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="小区Id,zb_village.id" prop="villageId" >-->
<!--        <a-input v-model="form.villageId" placeholder="请输入小区Id,zb_village.id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="名称" prop="title" >
        <a-input v-model="form.title" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="锁编号" prop="lockNo" >
        <a-input v-model="form.lockNo" placeholder="请输入锁编号" />
      </a-form-model-item>
      <a-form-model-item label="开锁指令" prop="lockOpenInstruction" >
        <a-input v-model="form.lockOpenInstruction" placeholder="请输入开锁指令" />
      </a-form-model-item>
      <a-form-model-item label="关锁锁指令" prop="lockCloseInstruction" >
        <a-input v-model="form.lockCloseInstruction" placeholder="请输入关锁锁指令" />
      </a-form-model-item>

      <a-form-model-item label="绑定楼栋" prop="isBindBuilding" >
        <a-select
          v-model="form.buildingId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear
          @change='changeBuilding'
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in listAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>


      <a-form-model-item label="绑定单元" prop="unitId" >
<!--        <a-input v-model="form.unitId" placeholder="请输入单元id，zb_village_unit.id" />-->
        <a-select
          v-model="form.unitId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in unitAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="锁状态0正常1离线" prop="lockStatus" >-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLock, addLock, updateLock } from '@/api/village/lock'
import {listBuilding} from '@/api/village/building'
import {listUnit} from '@/api/village/unit'
import { listVillage } from '@/api/village/village'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      listAll: [],
      unitAll:[],
      // 表单参数
      form: {
        id: null,

        villageId: null,

        title: null,

        lockNo: null,

        lockOpenInstruction: null,

        lockCloseInstruction: null,

        isBindBuilding: null,

        buildingId: null,

        unitId: null,

        lockStatus: 0,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        villageId: null,
        title: null,
        lockNo: null,
        lockOpenInstruction: null,
        lockCloseInstruction: null,
        isBindBuilding: null,
        buildingId: null,
        unitId: null,
        lockStatus: 0,
        createTime: null,
        remark: null
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (villageId) {
      this.reset()
      this.formType = 1
      this.open = true
      this.form.villageId = villageId
      this.buildingList(villageId)
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLock({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    changeBuilding(value){
      console.log("====",value)
      this.unitList(value)
    },
    buildingList(villageId){
      listBuilding({"villageId":villageId}).then(res => {
        this.listAll = res.data;
      })
    },
    unitList(buildingId){
      listUnit({"villageId":this.form.villageId,"buildingId":buildingId}).then(res => {
        this.unitAll = res.data;
        this.form.unitId = null;
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLock(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addLock(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
