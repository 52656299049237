import request from '@/utils/request'


// 查询楼栋列表
export function listBuilding(query) {
  return request({
    url: '/user/village-buildings/list',
    method: 'get',
    params: query
  })
}

// 查询楼栋分页
export function pageBuilding(query) {
  return request({
    url: '/user/village-buildings/page',
    method: 'get',
    params: query
  })
}

// 查询楼栋详细
export function getBuilding(data) {
  return request({
    url: '/user/village-buildings/detail',
    method: 'get',
    params: data
  })
}

// 新增楼栋
export function addBuilding(data) {
  return request({
    url: '/user/village-buildings/add',
    method: 'post',
    data: data
  })
}

// 修改楼栋
export function updateBuilding(data) {
  return request({
    url: '/user/village-buildings/edit',
    method: 'post',
    data: data
  })
}

// 删除楼栋
export function delBuilding(data) {
  return request({
    url: '/user/village-buildings/delete',
    method: 'post',
    data: data
  })
}
